.mpc-5-background-daytime-mode-202302-icon2 {
  width: 100vw;
  height: 134.688vw;
  object-fit: cover;
  position: absolute;
  top: 357.083vw;
  left: 0;
}

.mpc-5-for-developers {
  width: 39.7917vw;
  height: 2.70833vw;
  align-items: center;
  line-height: 2.91667vw;
  display: flex;
  position: absolute;
  top: 10.2083vw;
  left: 9.89583vw;
}

.mpc-5-frame-child1, .mpc-5-frame-child4 {
  border-radius: var(--mpc-5-br-9xs);
  background-color: var(--mpc-5-light-t5);
  width: .625vw;
  height: 8.33333vw;
  flex-shrink: 0;
  position: relative;
}

.mpc-5-frame-child4 {
  background-color: var(--mpc-5-dark-tanglepay);
}

.mpc-5-rectangle-group {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--mpc-5-gap-13xl);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 14.1667vw;
  left: 3.75vw;
}

.mpc-5-github, .mpc-5-vector-icon {
  width: 8.13%;
  position: absolute;
}

.mpc-5-vector-icon {
  height: 12.19%;
  max-width: 100%;
  max-height: 100%;
  inset: 25.63% 72.34% 62.19% 19.53%;
  overflow: hidden;
}

.mpc-5-github {
  height: 2.81%;
  font-size: var(--mpc-5-font1-size);
  align-items: center;
  font-weight: 800;
  display: flex;
  top: 39.53%;
  left: 19.48%;
}

.mpc-5-audit, .mpc-5-vector-icon1 {
  position: absolute;
  left: 19.53%;
}

.mpc-5-audit {
  height: 2.81%;
  width: 8.13%;
  font-size: var(--mpc-5-font1-size);
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  display: flex;
  top: 62.03%;
}

.mpc-5-vector-icon1 {
  height: 12.11%;
  width: 8.07%;
  max-width: 100%;
  max-height: 100%;
  top: 48.52%;
  bottom: 39.38%;
  right: 72.4%;
  overflow: hidden;
}

.mpc-5-iota-mpc, .mpc-5-iota-mpc-wrapper {
  align-items: center;
  display: flex;
}

.mpc-5-iota-mpc {
  width: 12.8125vw;
  height: 2.08333vw;
  flex-shrink: 0;
  line-height: 2.91667vw;
  position: relative;
}

.mpc-5-iota-mpc-swap {
  width: 8.33333vw;
  height: 2.08333vw;
  flex-shrink: 0;
  align-items: center;
  line-height: 2.91667vw;
  display: flex;
  position: relative;
}

.mpc-5-iota-mpc-wrapper {
  width: 11.875vw;
  flex-direction: column;
  justify-content: center;
}

.mpc-5-learn-more-child {
  height: 100%;
  width: 100%;
  border-radius: var(--mpc-5-br-5xs);
  background-color: var(--mpc-5-light-t1-day);
  position: absolute;
  inset: 0;
}

.mpc-5-learn-more1 {
  height: 80%;
  width: 93.75%;
  justify-content: center;
  align-items: center;
  line-height: 2.08333vw;
  display: flex;
  position: absolute;
  top: 10%;
  left: 3.57%;
}

.mpc-5-learn-more {
  width: 11.6667vw;
  height: 2.08333vw;
  text-align: center;
  font-size: var(--mpc-5-font2-size);
  color: var(--mpc-5-dark-t1);
  flex-shrink: 0;
  position: relative;
}

.mpc-5-scalable {
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: .20833vw solid var(--mpc-5-dark-b1);
  box-sizing: border-box;
  width: 15.3125vw;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl-1);
  justify-content: center;
  align-items: flex-start;
  gap: var(--mpc-5-gap-17xl);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 17.0833vw;
  left: 37.7083vw;
}

.mpc-5-scalable-audit {
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: .20833vw solid var(--mpc-5-dark-b1);
  box-sizing: border-box;
  width: 15.3125vw;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl-1);
  justify-content: center;
  align-items: flex-start;
  gap: var(--mpc-5-gap-17xl);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 32.1875vw;
  left: 37.7083vw;
}

.mpc-5-scalable3 {
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: .20833vw solid var(--mpc-5-dark-b1);
  box-sizing: border-box;
  width: 15.3125vw;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl-1);
  justify-content: center;
  align-items: flex-start;
  gap: var(--mpc-5-gap-17xl);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 17.0833vw;
  left: 79.375vw;
}

.mpc-5-scalable3-audit {
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: .20833vw solid var(--mpc-5-dark-b1);
  box-sizing: border-box;
  width: 15.3125vw;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl-1);
  justify-content: center;
  align-items: flex-start;
  gap: var(--mpc-5-gap-17xl);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 32.1875vw;
  left: 79.375vw;
}

.mpc-5-bridge-wrapper, .mpc-5-bridge1 {
  width: 12.5vw;
  justify-content: center;
  display: flex;
}

.mpc-5-bridge1 {
  height: 2.08333vw;
  flex-shrink: 0;
  align-items: center;
  line-height: 2.91667vw;
  position: relative;
}

.mpc-5-bridge-wrapper {
  flex-direction: column;
  align-items: flex-start;
}

.mpc-5-learn-more2 {
  width: 11.6667vw;
  height: 2.08333vw;
  font-size: var(--mpc-5-font2-size);
  color: var(--mpc-5-dark-t1);
  flex-shrink: 0;
  position: relative;
}

.mpc-5-child, .mpc-5-scalable1 {
  box-sizing: border-box;
  position: absolute;
  top: 17.0833vw;
}

.mpc-5-scalable1-audit {
  box-sizing: border-box;
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: .20833vw solid var(--mpc-5-dark-b1);
  width: 14.7917vw;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl);
  justify-content: center;
  align-items: center;
  gap: var(--mpc-5-gap-17xl);
  text-align: center;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 32.1875vw;
  left: 59.4792vw;
}

.mpc-5-scalable1 {
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: .20833vw solid var(--mpc-5-dark-b1);
  width: 14.7917vw;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl);
  justify-content: center;
  align-items: center;
  gap: var(--mpc-5-gap-17xl);
  text-align: center;
  flex-direction: column;
  display: flex;
  left: 59.4792vw;
}

.mpc-5-child {
  border-right: .20833vw solid var(--mpc-5-light-t1-day);
  width: .20833vw;
  height: 11.1458vw;
  left: 33.8021vw;
}

.mpc-5-learn-more4 {
  width: 8.125vw;
  height: 4.58333vw;
  text-align: center;
  font-size: var(--mpc-5-font2-size);
  color: var(--mpc-5-dark-t1);
  position: absolute;
  top: 44.9479vw;
  left: 19.5313vw;
}

.mpc-5-div1 {
  width: 100%;
  height: 66.6667vw;
  text-align: left;
  font-size: var(--mpc-5-font3-size);
  color: var(--mpc-5-dark-b1);
  font-family: var(--mpc-5-font);
  position: relative;
  overflow: hidden;
}

/*# sourceMappingURL=mpc.eb35be8d.css.map */
