.mpc-5-background-daytime-mode-202302-icon2 {
  position: absolute;
  top: 6856px;
  left: 0;
  width: 1920px;
  height: 2586px;
  object-fit: cover;
}
.mpc-5-for-developers {
  position: absolute;
  top: 196px;
  left: 190px;
  line-height: 56px;
  display: flex;
  align-items: center;
  width: 764px;
  height: 52px;
}
.mpc-5-frame-child1,
.mpc-5-frame-child4 {
  position: relative;
  border-radius: var(--mpc-5-br-9xs);
  background-color: var(--mpc-5-light-t5);
  width: 12px;
  height: 160px;
  flex-shrink: 0;
}
.mpc-5-frame-child4 {
  background-color: var(--mpc-5-dark-tanglepay);
}
.mpc-5-rectangle-group {
  position: absolute;
  top: 272px;
  left: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--mpc-5-gap-13xl);
}
.mpc-5-github,
.mpc-5-vector-icon {
  position: absolute;
  width: 8.13%;
}
.mpc-5-vector-icon {
  height: 12.19%;
  top: 25.63%;
  right: 72.34%;
  bottom: 62.19%;
  left: 19.53%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.mpc-5-github {
  height: 2.81%;
  top: 39.53%;
  left: 19.48%;
  font-size: var(--mpc-5-font1-size);
  font-weight: 800;
  display: flex;
  align-items: center;
}
.mpc-5-audit,
.mpc-5-vector-icon1 {
  position: absolute;
  left: 19.53%;
}
.mpc-5-audit {
  height: 2.81%;
  width: 8.13%;
  top: 62.03%;
  font-size: var(--mpc-5-font1-size);
  font-weight: 800;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mpc-5-vector-icon1 {
  height: 12.11%;
  width: 8.07%;
  top: 48.52%;
  right: 72.4%;
  bottom: 39.38%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.mpc-5-iota-mpc,
.mpc-5-iota-mpc-wrapper {
  display: flex;
  align-items: center;
}
.mpc-5-iota-mpc {
  position: relative;
  line-height: 56px;
  width: 246px;
  height: 40px;
  flex-shrink: 0;
}
.mpc-5-iota-mpc-swap {
  position: relative;
  line-height: 56px;
  width: 160px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.mpc-5-iota-mpc-wrapper {
  width: 228px;
  flex-direction: column;
  justify-content: center;
}
.mpc-5-learn-more-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--mpc-5-br-5xs);
  background-color: var(--mpc-5-light-t1-day);
}
.mpc-5-learn-more1 {
  position: absolute;
  height: 80%;
  width: 93.75%;
  top: 10%;
  left: 3.57%;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mpc-5-learn-more {
  position: relative;
  width: 224px;
  height: 40px;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--mpc-5-font2-size);
  color: var(--mpc-5-dark-t1);
}
.mpc-5-scalable {
  position: absolute;
  top: 328px;
  left: 724px;
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: 4px solid var(--mpc-5-dark-b1);
  box-sizing: border-box;
  width: 294px;
  display: flex;
  flex-direction: column;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl-1);
  align-items: flex-start;
  justify-content: center;
  gap: var(--mpc-5-gap-17xl);
}
.mpc-5-scalable-audit {
  position: absolute;
  top: 618px;
  left: 724px;
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: 4px solid var(--mpc-5-dark-b1);
  box-sizing: border-box;
  width: 294px;
  display: flex;
  flex-direction: column;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl-1);
  align-items: flex-start;
  justify-content: center;
  gap: var(--mpc-5-gap-17xl);
}
.mpc-5-scalable3 {
  position: absolute;
  top: 328px;
  left: 1524px;
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: 4px solid var(--mpc-5-dark-b1);
  box-sizing: border-box;
  width: 294px;
  display: flex;
  flex-direction: column;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl-1);
  align-items: flex-start;
  justify-content: center;
  gap: var(--mpc-5-gap-17xl);
}
.mpc-5-scalable3-audit {
  position: absolute;
  top: 618px;
  left: 1524px;
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: 4px solid var(--mpc-5-dark-b1);
  box-sizing: border-box;
  width: 294px;
  display: flex;
  flex-direction: column;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl-1);
  align-items: flex-start;
  justify-content: center;
  gap: var(--mpc-5-gap-17xl);
}
.mpc-5-bridge-wrapper,
.mpc-5-bridge1 {
  width: 240px;
  display: flex;
  justify-content: center;
}
.mpc-5-bridge1 {
  position: relative;
  line-height: 56px;
  align-items: center;
  height: 40px;
  flex-shrink: 0;
}
.mpc-5-bridge-wrapper {
  flex-direction: column;
  align-items: flex-start;
}
.mpc-5-learn-more2 {
  position: relative;
  width: 224px;
  height: 40px;
  flex-shrink: 0;
  font-size: var(--mpc-5-font2-size);
  color: var(--mpc-5-dark-t1);
}
.mpc-5-child,
.mpc-5-scalable1 {
  position: absolute;
  top: 328px;
  box-sizing: border-box;
}

.mpc-5-scalable1-audit {
  position: absolute;
  top: 618px;
  box-sizing: border-box;
  left: 1142px;
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: 4px solid var(--mpc-5-dark-b1);
  width: 284px;
  display: flex;
  flex-direction: column;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl);
  align-items: center;
  justify-content: center;
  gap: var(--mpc-5-gap-17xl);
  text-align: center;
}

.mpc-5-scalable1 {
  left: 1142px;
  border-radius: var(--mpc-5-br-9xl);
  background-color: var(--mpc-5-dark-t1);
  border: 4px solid var(--mpc-5-dark-b1);
  width: 284px;
  display: flex;
  flex-direction: column;
  padding: var(--mpc-5-padding-29xl) var(--mpc-5-padding-39xl);
  align-items: center;
  justify-content: center;
  gap: var(--mpc-5-gap-17xl);
  text-align: center;
}
.mpc-5-child {
  left: 649px;
  border-right: 4px solid var(--mpc-5-light-t1-day);
  width: 4px;
  height: 214px;
}
.mpc-5-learn-more4 {
  position: absolute;
  top: 863px;
  left: 375px;
  width: 156px;
  height: 88px;
  text-align: center;
  font-size: var(--mpc-5-font2-size);
  color: var(--mpc-5-dark-t1);
}
.mpc-5-div1 {
  position: relative;
  width: 100%;
  height: 1280px;
  overflow: hidden;
  text-align: left;
  font-size: var(--mpc-5-font3-size);
  color: var(--mpc-5-dark-b1);
  font-family: var(--mpc-5-font);
}